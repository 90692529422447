import { Methods } from '../Methods/methods.model';

import { Answers } from '../Answers/answers.model';

import { FlooringType } from '../FlooringType/flooringType.model';

import { serializable, alias, object, list, primitive } from 'serializr';

export class Floorings {
  @serializable(alias('id', primitive()))
  id?: number;

  @serializable(alias('created_at', primitive()))
  createdAt?: number;

  @serializable(alias('updated_at', primitive()))
  updatedAt?: number;

  @serializable(alias('flooring_type', object(FlooringType)))
  flooringType?: FlooringType;

  @serializable(alias('total_cost', primitive()))
  totalCost?: number;

  @serializable(alias('quantity', primitive()))
  quantity?: number;

  @serializable(alias('cost_per_area', primitive()))
  costPerArea?: number;

  @serializable(alias('cost_per_unit', primitive()))
  costPerUnit?: number;

  @serializable(alias('flooring_unit', primitive()))
  flooringUnit?: string;

  @serializable(alias('is_manual', primitive()))
  isManualPrice?: boolean;

  @serializable
  unit?: string;

  @serializable(alias('weight', primitive()))
  weight?: number;

  @serializable(alias('answers', list(object(Answers))))
  answers?: Answers[] = [];

  @serializable(alias('methods', list(object(Methods))))
  methods?: Methods[] = [];

  @serializable(list(primitive()))
  reclamationMethod?: string[];

  @serializable(alias('factor_to_pound', primitive()))
  factorToPound?: string;

  @serializable(alias('commodity_description', primitive()))
  commodityDescription?: string;
}
