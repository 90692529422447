import { serializable, alias, primitive } from 'serializr';

export class Summary {
  @serializable(alias('recycle_cost', primitive()))
  recycleCost?: number;

  @serializable(alias('freight_cost', primitive()))
  freightCost?: number;

  @serializable(alias('admin_cost', primitive()))
  adminCost?: number;

  @serializable(alias('total_cost', primitive()))
  totalCost?: number;

  @serializable(alias('rental_cost', primitive()))
  rentalCost?: number;

  @serializable(alias('cost_per_sy', primitive()))
  costPerSy?: number;

  @serializable(alias('total_sy', primitive()))
  totalSy?: number;

  @serializable(alias('total_weight', primitive()))
  totalWeight?: number;

  @serializable(alias('reuse_sy', primitive()))
  reuseSy?: number;

  @serializable(alias('reuse_weight', primitive()))
  reuseWeight?: number;

  @serializable(alias('recycle_sy', primitive()))
  recycleSy?: number;

  @serializable(alias('recycle_weight', primitive()))
  recycleWeight?: number;

  @serializable(alias('wte_sy', primitive()))
  wteSy?: number;

  @serializable(alias('wte_weight', primitive()))
  wteWeight?: number;

  @serializable(alias('additional_fee', primitive()))
  additionalFee?: number;

  @serializable(alias('rental_fee', primitive()))
  rentalFee?: number;

  @serializable(alias('mobilization_fee', primitive()))
  mobilizationFee?: number;
}
