// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offline {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  inset: 0;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 9999;
}
.offline__text {
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-size: 28px;
  padding: 20px 30px;
}`, "",{"version":3,"sources":["webpack://./src/shared/components/Offline/offline.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,oCAAA;EACA,aAAA;EACA,QAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,aAAA;AACF;AACE;EACE,oCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".offline {\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.5);\n  display: flex;\n  inset: 0;\n  justify-content: center;\n  position: fixed;\n  width: 100%;\n  z-index: 9999;\n\n  &__text {\n    background-color: rgb(255, 255, 255);\n    border-radius: 8px;\n    color: rgba(0, 0, 0);\n    font-size: 28px;\n    padding: 20px 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
