import { Button as AntButton } from 'antd';
import { ButtonType, ButtonProps as AntdButtonProps } from 'antd/lib/button';
import React, { FC, ReactNode, Ref } from 'react';
import './button.scss';
import { ButtonComponentTheme } from 'src/enums/buttonComponent.enum';

interface ButtonProps extends Omit<AntdButtonProps, 'onChange'> {
  onClick?: () => void;
  children: ReactNode;
  htmlType?: 'reset' | 'submit' | 'button';
  type?: ButtonType;
  size?: 'small' | 'large';
  loading?: boolean;
  name?: string;
  buttonRef?: Ref<HTMLButtonElement>;
  theme?: ButtonComponentTheme;
}

const Button: FC<ButtonProps> = ({ className, ...props }) => {
  const {
    onClick,
    children,
    htmlType = 'button',
    size,
    loading,
    name,
    type,
    buttonRef,
    theme
  } = props;

  return (
    <div className={`button ${theme} ${className}`}>
      <AntButton
        {...props}
        loading={!!loading}
        className={`${type} ${size}`}
        htmlType={htmlType}
        onClick={onClick}
        name={name}
        type={type}
        data-testid="button"
        ref={buttonRef}
        data-html2canvas-ignore="true"
      >
        {children}
      </AntButton>
    </div>
  );
};

export default Button;
